.dropzone-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ccc;
  border-radius: 5px;
  height: 200px;
  padding: 10px;
}

.dropzone-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.dropzone-content p {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  color: #aaa;
}

.dropzone-container:hover {
  cursor: pointer;
  border-color: #007bff;
}

.dropzone-container:hover .dropzone-content p {
  color: #007bff;
}
